.Calendar {
	background: rgb(235, 159, 47) url('bg-calendar.jpg') no-repeat fixed center top;
  background-size: cover;
  min-height: calc(100vh - 66px);
  --fc-button-active-bg-color: #cccccc;
  --fc-button-bg-color: #f5f5f5;
  --fc-button-text-color: #333333;
  --fc-button-hover-bg-color: #e6e6e6;
}

.subscribe {
  margin-top: 1em;
}

.extra-margin {
  margin-bottom: 1em;
}

.fc-view {
	background-color: #FFF;
}
.fc-toolbar > div {
	margin: 0 0 .5em 0;
}

.fc-toolbar-chunk {
  display: inline-block;
}

.fc-toolbar {
  flex-wrap: wrap;
}

.fc-toolbar-title {
  background-color: #FFC107;
}
h2.fc-toolbar-title {
  font-size: 2em;
  font-weight: bold;
  font-family: 'Corben', cursive;
}
.fc-view {
  border: 5px solid #FFF;
}
.fc-col-header .fc-day {
  background-color: #FFC107;
}
.fc-unthemed .fc-today {
  background: rgba(253, 216, 53, 0.3);
}

.fc-button-active {
  background-color: #cccccc;
}

.calendar-modal-content {
  width: 90%;
  max-width: none !important;
  margin-left: auto;
  margin-right: auto;
}

.calendar-modal-dialog {
  width: 90%;
  max-width: none !important;
}

.calendar-modal-dialog .leaflet-container {
  height: 500px;
}

@media only screen and (max-width: 775px) {
  .fc-toolbar {
    flex-direction: column;
  }

  .container {
    min-width: calc(100vw - 20px);
  }

  /* Weird things happen to the backdrop on strange screen sizes */
  .modal-backdrop {
    min-width: calc(max(100vw, 800px)) !important;
    min-height: calc(max(100vh, 20000px)) !important;
  }
}
