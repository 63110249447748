.PageContent {
  min-height: calc(100vh - 66px);
}

.WH3 {
  background-image: url('bg-home.gif');
}

.WH3-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.WH3-link {
  color: #61dafb;
}

.Kennels {
  background-image: url('bg-kennels.jpg');
  color: #000080;
}

.Kennels .header {
  background-color: #39F;
  font-family: Impact, Charcoal, sans-serif;
}

.Kennels .logo {
  margin-left: 0.25em;
}

.Kennels .kennel {
  border: 4px solid #0FF;
  padding: 0.5em;
  font-family: Times New Roman, serif;
}

.kennel h2 {
  text-decoration: underline;
}

.kennel h2 img {
  height: 2em;
}

.KennelLinks ul {
  list-style: none;
  padding: 0;
}

.kennel span {
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.KennelSong p.title {
  font-weight: bold;
  margin-bottom: 0;
}

.KennelSong p.lyrics {
  text-align: center;
  white-space: pre-wrap;
  font-style: italic;
}

.markdown {
  margin-bottom: 1em;
}

.markdown p {
  margin-bottom: 0;
}

.less-nerdy {
  text-decoration: line-through;
}

.raw-container {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.raw-container iframe {
  width: 100vw;
  height: calc(100vh - 73px);
  border: none;
  background-color: #ffffff;
}

.eventContent {
  white-space: pre-wrap;
}

.event-header h2 a {
  margin-left: 0.15em;
}

.event-metadata{
  font-size: 90%;
  color: #555555;
  font-weight: bold;
}

.event-start-date {
  margin-left: 0.5em;
}
